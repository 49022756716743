import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import FaqComponent from "../../components/mdxComponents/FaqComponent";

const breadCrumbLevels = {
  Home: "/",
  "Google Analytics Beratung": "/de/google-analytics-beratung",
  "Data Layer": "/de/data-layer"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "de",
    href: "/de/data-layer"
  },
  {
    hreflang: "en",
    href: "/en/data-layer"
  },
  {
    hreflang: "x-default",
    href: "/en/data-layer"
  }
];

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Layer | Erklärung, Tips und Implementierung"
        description="Ein Data Layer ist ein zentrales Konzept wenn es um Web Analytics geht. Doch welche Probleme löst er eigentlich und wie verläuft die Implementierung?"
        lang="de"
        canonical="/de/data-layer"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        datePublished="2020-09-05T04:32:43.188Z"
        dateModified="2023-11-15T15:21:48.881Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualisierung des Google Tag Manager Data Layers in der Browserkonsole"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Layer</H>
        <p>Im Zusammenhang mit <Link to="/de/tag-management">Tag Management</Link> und Web Analytics fällt oft der Begriff <b>Data Layer.</b></p>
        <p>Ein Data Layer ist das fundamentale Element in einem ambitionierten Analytics Setup, da alle Tracking-Regeln auf ihn aufbauen.</p>
        <p>
          In der Web-Analytics Branche wird der Data Layer daher oft wie eine unverhandelbare Voraussetzung für ein
          Analytics Setup behandelt. Es gibt jedoch auch Situationen, in denen ein Data Layer unnötig ist.
        </p>
        <p>
          In den folgenden Zeilen möchte ich daher erklären,{" "}
          <Link to="/de/data-layer#was-ist-ein-data-layer">was ein Data Layer ist</Link>,{" "}
          <Link to="/de/data-layer#welches-problem-l">welches Problem er löst</Link> und die Unterschiede zwischen
          einem <Link to="/de/data-layer#google-tag-manager-data-layer">Google Tag Manager Data Layer</Link> und dem{" "}
          <Link to="/de/data-layer#data-layer-f">Adobe Data Layer</Link>.{" "}
        </p>
        <p>
          Danach betrachten wir, die Data Layer <b>Implementierung</b> und durchlaufen schrittweise das{" "}
          <Link to="/de/data-layer#1">Design</Link>, dann die <Link to="/de/data-layer#2">Implementierung</Link> und das{" "}
          <Link to="/de/data-layer#3">Debugging</Link> für die gängigen Tag Management Systeme.
        </p>
        <H as="h2">Was ist ein Data Layer?</H>
        <p>
          Ein Data Layer ist eine <b>Datenstruktur</b> die relevante Informationen in Key-Value Pairs zur Nutzung mit z.B. <Link to="/de/tag-management">Tag Management Systemen</Link> bereitstellt.
        </p>
        <p>Ein Data Layer ist im globalen JavaScript Scope der Webseite als <b>Array</b> oder <b>Object</b> verfügbar und hält Daten in strukturierter Form für andere Programme verfügar.</p>
        <p>Der Nutzen eines Data Layers liegt in einem programmatisch einfachem Zugang zu relevanten Daten während eines Webseitenaufrufs.</p>
        <p>
          Der Data Layer macht Daten zentral abrufbar und ist Ausgangspunkt für eine datenbasierte Tracking- und
          Analytics-Logik in <Link to="/de/tag-management#was-ist-ein-tag-management-system">Tag Management Systemen</Link>.
        </p>
        <SnippetJS caption="Code Snippet zum Erstellen eines Data Layers mit Google Tag Manager.  Es wird ein JavaScript-Objekt mit allen wichtigen Daten für die spätere Datenanalyse oder Regelerstellung im Tag Management System erstellt.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];`}
        </SnippetJS>
        <p>
          Mit einem Data Layer wird also ein zentraler Datenspeicher erschaffen, um Daten aus verschiedenen Quellen zu
          sammeln und zu beobachten.
        </p>
        <p>
          Dieser Ansatz (“single point of truth”) ermöglicht, dass nur eine Datenquelle für die Regelausführung
          beobachtet werden muss und kein Zweifel über die aktuellen Werten einer Variable aufkommt.
        </p>
        <p>
          Der Data Layer <b>wird bei jedem Seitenaufruf neu erstellt</b> und mit den jeweiligen Daten der neu
          aufgerufenen Seite ausgestattet.
        </p>
        <p>
          <b>Hinweis:</b> Bei Single-Page-Applications (SPAs) entsteht kein neuer Ladevorgang während der
          Seitennavigation. Deshalb wird ein Data Layer für Single-Page-Applications anders konfiguriert, als für
          klassische Webseiten mit Ladevorgängen.
        </p>
        <p>
          Die Daten im Data Layer repräsentieren Merkmale und Ereignisse der jeweiligen Unterseite. Jedes Merkmal wird
          durch ein Schlüsselwort und einen zugeteilten Wert beschrieben (<em>Key-Value-Pairs</em>).
        </p>
        <SnippetJS caption="Für die jeweilige Unterseite werden die Kategorie, der Name und die gewählte Sprache festgehalten. Sie können somit als Dimensionen in der Datenanalyse mit z.B. Google Analytics oder für die Regelerstellung im Tag Management System genutzt werden.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page", //Kategorie
  "pageName": "sneaker overview", //Name
  "language": "en-US", //Sprache
}];`}
        </SnippetJS>
        <p>
          Das Ziel dabei ist, diese Daten im TMS zugänglich zu machen, damit sie mit anderen Plattformen, wie z.B.
          Google Analytics oder Facebook Ads, genutzt werden können.
        </p>
        <p>
          Um diese Integration zu erreichen, hält das TMS Referenzen zu den Schlüsselwörtern und kann dann
          regelbasiert Tags ausführen, wenn die Schlüsselwörter einen bestimmten Wert annehmen oder sich ändern.
        </p>
        <p>
          <b>Beispiel:</b> Ein Besucher zoomt in ein Produktbild und ein Ereignis “Produkt Zoom” wird zu Google
          Analytics gesendet. Das Ereignis alleine ist nicht sehr aufschlussreich. Daher sendet man gleichzeitig
          andere Daten, wie den Produktnamen, die Produktkategorie und den Preis. Mit diesen zusätzlichen Daten kann
          das Ereignis in einem besseren Kontext analysiert werden.
        </p>
        <p>
          Man könnte so z.B. überprüfen, ob einige Produktkategorien eine vergleichsweise hohe Anzahl an
          Produkt-Zoom-Ereignissen aufweisen, um anschließend zusätzliche Produktbilder in diesen Kategorien
          zugänglich zu machen.
        </p>
        <p>
          Die <b>Quintessenz meiner Erklärung des Data Layers</b> ist, dass man Schlüsselwörtern Werte zuordnet und
          diese Werte zentral speichert.
        </p>
        <p>
          Die Werte sind oft wichtige Merkmale oder Inhaltskategorien, die entweder für die Datenanalyse oder für das
          Erstellen einer Tracking Logik wichtig sind.
        </p>
        <p>
          Um besser zu verstehen was ein Data Layer ist, kann man sich als bildliche Vereinfachung eine Excel-Tabelle
          vorstellen. Die Tabelle hält die für die Seite wichtigen Schlüsselwörter in den Spaltenüberschriften mit den
          jeweils zugeordneten Werten:
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Vereinfachtes Beispiel eines data layer in Excel"
            className="article-img"
            caption="Einen Datalayer kann man sich vereinfacht wie eine Tabelle vorstellen, in der bestimmten Schlüsselwörtern (Spaltenüberschrift) Werte zugeordnet werden, die Informationen der Webseite widerspiegeln."
          />
        </ImgContainerFixed>
        <H as="h2">Welches Problem löst ein Data Layer?</H>
        <p>
          Während ein Besucher mit der Webseite interagiert, werden mehrere Ereignisse ausgeführt: Klicks auf Buttons,
          das Ausfüllen von Kontaktformularen oder das Ansehen von Videos und Bildern.
        </p>
        <p>
          Insofern die Ereignisse Rückschlüsse auf das User Engagement ermöglichen, werden sie getrackt und es werden
          zusätzliche Daten mitgesendet, die z.B. die Besucher, die Session oder das Ereignis und das genutzte
          HTML-Element näher beschreiben.
        </p>
        <p>
          Und hier liegt der Knackpunkt: Die zusätzlichen Daten kommen häufig aus verschiedenen Datenquellen z.B. vom{" "}
          <b>Frontend</b>, der <b>Datenbank </b>oder von einem <b>API</b>.
        </p>
        <p>
          Um zu verstehen, welche Probleme ein Data Layer löst, müssen wir erst wissen, welche Probleme entstehen,
          wenn wir ohne Data Layer versuchen Daten aus diesen Datenquellen zu gewinnen.
        </p>
        <p>
          Lasst uns also mit Hilfe eines Beispiels die Beschaffung der Daten aus verschiedenen Datenquellen (Frontend,
          Datenbank und API) durchdenken:
        </p>
        <p>
          <b>Beispiel: </b>Der Besucher kauft ein Produkt auf der Webseite. Für den Produktkauf könnten folgende Daten
          von Interesse sein:
        </p>
        <ul
        // TODO: show list in 2 columns, problem: looks good on desktop but not on mobile
        // style={{ columns: "2", "-webkit-columns": "2", "-moz-columns": "2" }}
        >
          <li>Produktname</li>
          <li>Produktpreis</li>
          <li>Produktgröße</li>
          <li>Produktkategorie</li>
          <li>Produktfarbe</li>
          <li>Wert des Einkaufskorbs</li>
          <li>Marke</li>
          <li>Erstkauf</li>
          <li>Kundengruppe</li>
          <li>Kundenrabatt</li>
          <li>Geschlecht</li>
          <li>Land</li>
        </ul>
        <p>
          Für gewöhnlich ist eine Transaktion abgeschlossen, sobald man auf einer Danke-Seite landet, die die Details
          der Transaktion auflistet.
        </p>
        <p>
          <b>Frontend</b>: Um alle Daten mit der Transaktion zu verknüpfen, könnten wir die <em>Produktdaten</em>, den{" "}
          <em>Wert des Einkaufskorbs</em> und die <em>Marke </em>vermeintlich von der Danke-Seite scrapen.
        </p>
        <p>
          Das Hauptproblem beim Scrapen aus dem Frontend ist, dass die benötigten Daten auf der Seite vorhanden sein
          müssen. Denn das ist in der Realität eher selten der Fall.
        </p>
        <p>
          Außerdem ist es üblich, Ereignisse weitestgehend mit den selben Dimensionen zu tracken. Dadurch kann man sie
          später einfach vergleichen.
        </p>
        <p>
          Um also nicht auf allen Seiten künstlich die Daten in den Inhalt integrieren zu müssen, nutzt man einen Data
          Layer. Er macht die Daten zugänglich, ohne dass sie auf der Seite sichtbar sein müssen.
        </p>
        <p>
          Eine anderer Nachteil beim direkten Scrapen vom Frontend ist, das es nicht robust genug ist. Sobald
          Code-Änderungen implementiert werden und sich dadurch die Struktur der relevanten HTML-Elemente verändert,
          funktioniert es nicht mehr und muss neu aufgesetzt werden.
        </p>
        <p>
          <b>Datenbank: </b>Bei den Kundendaten (<em>Kundengruppe, Rabatt, Geschlecht, Erstkauf</em>) wird es
          komplizierter: Kundendaten müssten entweder zusammen mit der Serverantwort kommen oder von einem separaten
          API.
        </p>
        <p>
          Bei der Abfrage durch einen API ergibt sich jedoch ein Sicherheitsproblem, da die Authentifizierungsdaten
          für den API nicht im Browser hantiert werden sollten. Geübte Nutzer könnten sonst leicht Zugang zu
          Authentifizierungsdaten bekommen.
        </p>
        <p>
          Die Daten standardmäßig ohne Aufforderung mit der Serverantwort mitzusenden ist die beste Lösung, da so der
          Authentifizierungsstatus der Webseite genutzt werden kann: Ist man als Nutzer eingeloggt, werden die Daten
          an den Browser mitgesendet, andernfalls nicht.
        </p>
        <p>
          <b>API: </b>Geografische Daten, wie z.B. das Land des Besuchers, könnten über externe Dienste mittels eines
          APIs abgerufen werden.
        </p>
        <p>
          Nachteile sind hier, genau wie beim Zugang zum Datenbank API, dass das Passwort oder der Zugangsschlüssel
          zum API im Browser hantiert werden müsste und somit für geübte Nutzer im lokalen Code des Browsers
          zugänglich wären.
        </p>
        <p>
          Ein anderer Nachteil ist, dass man die Antwort des APIs abwarten muss. Wenn der User in der Zwischenzeit zu
          einer anderen Seite navigiert, könnte ein Ereignis verloren gehen.
        </p>
        <p>
          Jetzt wo wir die einzelnen Herausforderungen beim Einsammeln von Daten ohne Data Layer verstehen, können wir
          die Vorteile des Data Layers erkennen:
        </p>
        <H as="h3">Vorteile eines Data Layers</H>
        <ul>
          <li>Daten sind verfügbar unabhängig davon was sichtbar auf der Seite ist</li>
          <li>robustes Datensammeln</li>
          <li>sicherer Gebrauch sensibler Daten</li>
          <li>Pobleme mit asynchroner Datenabfrage werden minimiert</li>
        </ul>
        <H as="h2">Warum du (wahrscheinlich) einen Data Layer brauchst</H>
        <p>
          Durch die Erschaffung eines Data Layers wird beim Seitenaufbau jeder Unterseite ein JavaScript-Objekt mit
          den benötigten Daten im globalen Scope des Browsers zugänglich gemacht.
        </p>
        <p>
          Die Daten können aus der Datenbank, dem Frontend oder von APIs stammen, d.h. die Datenerfassung verläuft
          insgesamt sicherer, verlässlicher und Probleme mit sich verändernder HTML-Struktur werden minimiert.
        </p>
        <p>
          Zusätzlich wird ein Analytics Setup unabhängig davon das Daten auf den Unterseiten vorhanden sein müssen.
          Daten aus der Datenbank können mit einem Data Layer auf allen Unterseitein einer Webseite zugänglich gemacht
          werden, ohne dass sie zu sehen sein müssen.
        </p>
        <p>
          Ich rate grundsätzlich dazu einen Data Layer zu implementieren, da die Vorteile in puncto Datenqualität,
          Verlässlichkeit und die damit zusammenhängenden Zeitersparnisse mittelfristig, den höheren
          Implementierungsaufwand rechtfertigen.
        </p>
        <p>
          Das höhere Ziel von Web-Analytics ist letztlich datenbasierte, unternehmerische Entscheidungen zu treffen.
          Datenqualität sollte daher Priorität haben.
        </p>
        <p>
          Lasst uns einen Blick auf die verschiedenen Anbieter von Tag Management Systemen und die damit verbundenen
          Implikationen auf den Data Layer werfen. Danach schauen wir uns an, wie man einen Data Layer plant, designt
          und letztlich implementiert.
        </p>
        <H as="h2">Data Layer für Adobe Analytics, DTM, Launch und Tealium</H>
        <p>
          Data Layer können sich in Ihrer Struktur unterscheiden. Grundsätzlich unterscheidet man zwischen Data Layern
          mit <b>Objekt-basierter</b> oder <b>Array-basierter</b> Struktur.
        </p>
        <p>
          Gemäß der{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            Definition eines Data Layers
          </a>{" "}
          vom World Wide Web Consortium (W3C), folgt die Data Layer Struktur der Struktur eines JavaScript Objektes
          und wird inoffiziell <b>CEDDL</b> (Customer Experience Digital Data Layer) abgekürzt.
        </p>
        <p>In dem Data Layer Objekt können beliebig viele weitere Objekte oder auch Arrays vernestet werden:</p>
        <SnippetJS caption="Beispiel eines Data Layers für Adobe Analytics mit Objekt-basierter Struktur, gemäß der W3C Definition.">
          {`
window.digitalData = {
  pageName: "sneaker overview",
  destinationPath: "/en/sneakers",
  breadCrumbs: ["home","sneakers"],
  publishDate: "2020-07-01",
  language: "en-US"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics bzw. Adobe Launch</b> und <b>Tealium iQ</b> folgen der CEDDL Struktur. Im obigen Beispiel
          werden die Daten in einem Objekt <code>digitalData</code> gespeichert. Der Name ist jedoch nicht
          standardisiert und kann beliebig gewählt werden, muss jedoch im Tag Management System angegeben werden.
        </p>
        <p>
          Die Änderung der Werte kann abhängig vom TMS verschieden konfiguriert werden. Die einfachste Lösung ist
          jedoch die Werte zu überschreiben:
        </p>
        <SnippetJS caption="Um Daten im Data Layer zu ändern, können sie wie bei jedem anderen JavaScript-Objekt einfach überschrieben werden.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          Der zentrale Gedanke bei Objekt-basierten Data Layern ist, dass der Data Layer einmal beim Laden der Seite
          aufgebaut wird, jedoch nicht laufend während der User Journey verändert wird. Die Daten sind also in der
          Regel <b>statisch</b>.
        </p>
        <p>
          Ereignisse werden nicht im Data Layer erfasst, sondern mit separaten Tracking Codes direkt zu z.B. Adobe
          Analytics geschickt. Bei der Code-Ausführung werden andere relevante Daten vom Data Layer abgegriffen und
          mitgeschickt. Der Data Layer wird jedoch nicht verändert.
        </p>
        <SnippetJS caption="Event-Tracking wird bei Adobe mit Objekt-basiertem Data Layer über die _satellite Bibliothek ausgeführt.">
          {`
//Ereignis inkl. gewählter Farbe
_satellite.setVar("sneaker color", "black");
_satellite.track("select color"); 

`}
        </SnippetJS>
        <H as="h2">Adobe Launch mit Array-basiertem Data Layer nutzen</H>
        <p>Adobe launch kann verhältnismäßig einfach auch mit einem Array-basierten Data layer genutzt werden. </p>
        <p>
          Die Adobe Launch Extension <b>Data Layer Manager</b> macht es möglich. Hier Links zu weiterführenden
          Ressourcen:
        </p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo für den Gebrauch von Data Layer Manager mit Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension mit{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              Dokumentation
            </a>
          </li>
        </ul>
        <H as="h2">Google Tag Manager Data Layer</H>
        <p>
          Der Data Layer für Google Tag Manager, Matomo und Piwik Pro ist ein Array-basierter Data Layer und wird
          inoffiziell <b>EDDL</b> (Event Driven Data Layer) abgekürzt.
        </p>
        <p>
          Daten werden zwar auch in Objekten hantiert, jedoch ist die GTM Data Layer Struktur ein <b>Array mit Objekten</b>.
        </p>
        <SnippetJS caption="Code Snippet um einen Data Layer für Google Tag Manager zu erstellen.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];
`}
        </SnippetJS>
        <p>
          Bei einem Array-basierten Data Layer verläuft die Tracking-Logik anders: Es werden neue Objekte in das Array
          mittels <code>dataLayer.push()</code> gesendet. Die Änderung des Data Layers kann dann eine Trackingregel
          für ein Ereignis auslösen.
        </p>
        <p>
          Der fundamentale Unterschied zu Objekt-basierten Data Layern ist also das Änderungen durch Events
          gekennzeichnet werden und auf diese Änderungen die Regeln im Tag Management System aufgebaut sind.
          <br />
          Zusätzlich haben wir im Vergleich zum obigen <Link to="/de/data-layer#data-layer-f">Beispiel</Link> eine
          Abhängigkeit weniger, da zum Tracken keine andere Bibliothek wie <code>_sattelite</code> notwendig ist.
        </p>
        <p>
          Außerdem verändern sich die Daten in einem Google Tag Manager Data Layer während der User Journey, da der
          Nutzer durch Interaktionen Ereignisse auslöst, die die Daten dynamisch ändern.
        </p>{" "}
        <p>
          Ein Array-basierter Data Layer ist also Ausgangspunkt für das Event-Tracking und hantiert Daten flexibler,
          während ein Objekt-basierter Data Layer mehr als statischer Datenspeicher dient.
        </p>
        <p>
          Durch diese Flexibilität, wird Array-basierten Data Layern eine bessere Eignung für das Tracking von
          Single-Page-Applikationen nachgesagt. <br />
          Man kann SPAs jedoch auch mit Objekt-basierten Data Layern tracken, man muss nur etwas mehr
          benutzerdefinierten Code schreiben und ggf. entstehen ein paar Grenzfälle, die es zu lösen gilt.
        </p>
        <p>
          Wenn man jedoch am Anfang eines Tracking Projektes für eine Single-Page-Application steht und frei wählen
          kann, würde ich ein TMS mit einem Array-basierten Data Layer bevorzugen.
          <br />
          Einen bestehenden Adobe Analytics Data Layer widerum auf einen GTM Data Layer umzustellen, ist unnötig.
        </p>
        <H as="h2">Content Management Systeme inklusive Data Layer</H>
        <p>
          Wer eine <b>Wordpress </b>Webseite nutzt und daran interessiert ist einen Data Layer zu implementieren, kann
          sich freuen: Wordpress Nutzer können mit{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            diesem Plugin
          </a>{" "}
          Google Tag Manager und gleichzeitig einen vorkonfigurierten Data Layer implementieren.
        </p>
        <p>
          Danach verfügbare Daten im Data Layer sind z.B. Kategorien, Autorennamen und Publizierungsdaten, sowie
          Suchwörter der Suchfunktion.
        </p>
        <p>
          Die Daten können in den Einstellungen einfach per checkbox gewählt werden. Außerdem bietet das Plugin
          vorkonfigurierte Data Layer Events für gängige Kontaktformulare.
        </p>
        <p>
          Man bekommt sehr viel Funktionalität ohne viel Aufwand. Zusätzliche Ereignisse müssen jedoch manuell zum
          Code hinzugefügt werden.
        </p>
        <p>
          Wer einen Onlineshop mit <b>WooCommerce</b> für Wordpress betreibt, kann außerdem mit demselben Plugin einen{" "}
          <b>classic E-Commerce</b> und einen<b> enhanced E-Commerce Data Layer</b> erstellen, was ziemlich
          beeindruckend ist.
        </p>
        <p>
          Wer Tealium mit Wordpress nutzen möchte, findet ein ähnliches{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Plugin für Tealium
          </a>
          .
        </p>
        <p>
          Für <b>Drupal</b> gibt es das{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            Data Layer Plugin
          </a>
          .
        </p>
        <p>
          <b>Wix </b>und <b>Squarespace </b>Nutzer können Google Tag Manager über die Platform Tools installieren,
          aber müssen den Data Layer mittels JavaScript selber implementieren.
        </p>
        <H as="h2">Data Layer implementieren</H>
        <p>
          Wie implementiert man einen Data Layer selber? - Da die Planung und Implementierung eines Data Layers sich
          über die Bereiche Web Analytics und Backend- & Frontend-Programmierung erstreckt, verläuft sie in der Regel
          mit einem Webbüro bzw. Programmierer in Zusammenarbeit mit einem{" "}
          <Link to="/de/google-analytics-consultant">Analytics Consultant</Link>.
        </p>
        <p>
          Der Analytics Consultant brieft die Agentur oder den Programmierer, steuert das Projekt und validiert
          schließlich die Implementierung. Nach der Abnahme der Implementierung konfiguriert er das Tag Management
          System und das Analytics Tool.
        </p>
        <p>
          Wer neugierig ist und ein bisschen JavaScript beherrscht, kann versuchen einen Data Layer selbst zu
          implementieren. Im Folgenden also ein <b>Guide zum Data Layer selber machen</b>.
        </p>
        <p>Die Implementierung eines Data Layers durchläuft drei Phasen: </p>
        <ol>
          <li>
            <Link to="/de/data-layer#1">Data Layer Design</Link>
          </li>

          <li>
            <Link to="/de/data-layer#2">Implementierung</Link>
          </li>

          <li>
            <Link to="/de/data-layer#3">Debugging</Link>
          </li>
        </ol>
        <H as="h3">1. Data Layer Design</H>
        <p>In der Designphase wird geplant, welche Daten der Data Layer beinhalten sollte.</p>
        <p>
          Alle Merkmale von Besuchern, Sitzungen, Seiten, Produkten oder Ereignissen die aufschlussreich für die Ziele
          der Datenanalyse sind, sind relevant und sollten für die Architektur des Data Layers bedacht werden.
        </p>
        <p>
          Um zu entscheiden welche Daten im Data Layer gehalten werden sollten, ist es hilfreich zu überlegen, welche
          unternehmerischen Fragen es zu beantworten gilt. Mit dem Endresultat im Kopf, kann man danach alle möglichen
          Daten der Webseite ein- oder ausschließen.
        </p>
        <p>
          Abschließend sollte man die wichtigsten Dimensionen per Datenpunkt berücksichtigen und die notwendige
          Struktur beim Datensammeln von der finalen Datenanalyse aus rekonstruieren.
        </p>
        <p>
          <b>Beispiel:</b> Eine Sprachschule mit einer mehrsprachigen Wordpress Webseite möchte die Muttersprache,
          sowie das Sprachinteresse definieren, um ggf. eine Werbekampagne mit Facebook Ads zu starten, welche auf
          eine Zielgruppe mit ähnlichen Charakteristiken abziehlt.
        </p>
        <p>
          Folglich müssen für alle Seitentypen (z.B. Homepage, Kursseiten, Über Uns, Kontakt, Neuigkeiten) die
          relevanten Daten für die Datenanalyse definiert werden. Der Einfachheit halber, konzentrieren wir uns im
          unteren Beispiel auf die Homepage und die Kursseiten:
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-beispiel-sprachschule.png"
            alt="Beispiel für das Data Layer Design einer Sprachschule"
            caption="Beispiel eines Data Layers für die Homepage und Kursseiten einer Sprachschule."
          />
        </ImgContainerFixed>
        <p>
          <b>Beispiel:</b> Array-basierter Google Tag Manager Data Layer für die Sprachschule
        </p>
        <SnippetJS caption="Code Snippet um einen GTM Data layer für die Sprachschule zu initiieren. Beachte das Nummern als Strings getrackt werden.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": "de", //Sprachcode für gewählte Sprache
  "sessionDuration": "182", //Dauer der Sitzung in Sekunden
  "languageIntent": "es", //Meistbesuchte Kurssprache
  "pageType": "course page",
  "courseName": "Spanisch A1 - Anfänger",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Dauer in Wochen
}];
`}
        </SnippetJS>
        <p>
          <b>Beispiel:</b> Webseite einer Sprachschule mit Objekt-basiertem Data Layer für Adobe Launch
        </p>
        <SnippetJS caption="Initiierung des Data Layers für Adobe Launch oder Adobe DTM.">
          {`
window.digitalData = window.digitalData || {
  "language": "de", //Sprachcode für gewählte Sprache
  "sessionDuration": 182, //Dauer der Sitzung in Sekunden
  "languageIntent": "es", //Meistbesuchte Kurssprache
  "pageType": "course page",
  "courseName": "Spanisch A1 - Anfänger",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Dauer in Wochen
};
`}
        </SnippetJS>
        <H as="h3">2. Implementierung</H>
        <p>
          Um den Data Layer zu implementieren, muss <u>auf jeder Unterseite</u> ein Data Layer mit den jeweiligen
          Daten initiiert werden.
        </p>
        <p>
          Die obigen Beispiele zeigen den finalen, errechneten Data Layer (Data Layer im <em>computed state</em>).
          <br />
          Bei der Implementierung müssen die Daten jedoch aus den Datenquellen erhoben werden, damit sie im letztlich
          im Data Layer erscheinen. Der eigentliche Quelltext sieht also anders aus.
        </p>
        <p>
          Um ein realistisches Beispiel geben zu können, gehe ich von folgenden Prämissen für die Datenerhebung aus:
        </p>
        <ul>
          <li>
            Sitzungsdauer und das Sprachinteresse werden durch ein selbst erstelltes JavaScript im Speicher des
            Browsers festgehalten.
          </li>
          <li>
            Seitensprache, Seitentyp, sowie Kursdaten werden im Backend bestimmt und mit der Serverantwort in den
            Seitentemplates zugänglich gemacht.
          </li>
        </ul>
        <p>Der Quelltext im Backend sieht dann gemäß der obigen Prämissen in etwa wie folgt aus:</p>
        <SnippetJS caption="Beispiel für den Data Layer Quelltext vor der Errechnung der Daten.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
        </SnippetJS>
        <H as="h4">Event-Tracking mit Data Layer Push</H>
        <p>
          Bei Array-basierten Data Layern für GTM, Matomo oder Piwik Pro werden Daten mittels{" "}
          <code>dataLayer.push()</code> mit einem Event dem Data Layer hinzugefügt.
        </p>
        <SnippetJS caption="Data Layer Push Beispiel für Event-Tracking im Array-basierten Data Layer für GTM.">
          {`
window.dataLayer.push({
  "event": "course-booking", 
  "startWeek": "24"
});
`}
        </SnippetJS>
        <p>
          Event ist ein spezielles Schlüsselwort in Google Tag Manager und kann als benutzerdefiniertes Ereignis in
          GTM referiert werden.
        </p>
        <p>
          Das Tag Management System überwacht den Data Layer und führt eine beliebige Aktion aus, sobald ein
          bestimmter Event mittels <b>Data Layer Push</b> hinzugefügt wird.
        </p>
        <p>
          Sobald der Event in den Data Layer geschickt wird, übernimmt die Logik des TMS und sendet z.B. ein Ereignis
          an Google Analytics.
        </p>
        <p>
          Alle relevanten Daten, um den Event näher zu beschreiben (Name, Sprache, Niveau und Dauer) sind im Data
          Layer verfügbar - z.B. auch die <em>Startwoche</em>, die mit dem Event mitgesendet wurde.
        </p>
        <p>
          Derselbe Event würde bei einem <b>Objekt-basierten Data Layer</b> nicht im Data Layer, sondern von einem
          eigenständigen Code geschickt werden.
        </p>
        <p>Mit Adobe Launch würde das Code-Beispiel für den Event wie folgt aussehen:</p>
        <SnippetJS caption="Adobe Launch Beispiel für's Event-Tracking. Bemerke, dass das Ereignis direkt mit der _satellite Bibliothek zu Adobe Analytics geschickt wird und nicht zum Data Layer.">
          {`
_satellite.setVar("startWeek", 24);
_satellite.track("course-booking");
`}
        </SnippetJS>
        <H as="h4">Positionierung im Quelltext</H>
        <p>
          Der Data Layer code wird <u>oben im Quelltext vor dem Tag Management System</u> implementiert, damit die
          Daten zugänglich sind, wenn das TMS lädt.
        </p>
        <p>
          <b>Beispiel:</b> Data Layer Positionierung im Quelltext
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="Screenshot vom Quelltext einer Webseite um die Positionierung des Data Layer code for der Tag Management Bibliothek zu veranschaulichen"
          caption="Der Data Layer sollte vor dem Tag Management System geladen werden, damit die Daten sofort im TMS verfügbar sind."
        />
        <H as="h3">3. Debugging</H>
        <p>
          Die typischen Szenarien zum Debuggen eines Data Layers sind entweder, das Auslesen von Data Layer Variablen
          oder das Simulieren von Ereignissen, um zu sehen, wie sich der Data Layer verändert.
        </p>
        <p>
          Da das Data Layer Objekt global zugänglich ist, können Variablenwerte einfach in der Browserkonsole
          ausgelesen werden.
        </p>
        <p>
          Um den Data Layer zu untersuchen, kannst Du die folgenden Variablennamen in der Browserkonsole eingeben
          (vorausgesetzt die üblichen Namenskonventionen werden genutzt):
        </p>
        <p>
          <b>Webseiten mit GTM DataLayer</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Webseiten mit Adobe Launch oder DTM Data Layer</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium Data Layer</b>
        </p>
        <p>
          <code>utag.data</code> oder <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager hat sogar einen eigenen <b>Debugger Mode</b>, der aktiviert wird, wenn man oben rechts
          auf <b>in Vorschau ansehen </b>klickt.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode-aktivierung.png"
          alt="Screenshot von Google Tag Manager um den Debugger Mode zu aktivieren"
          caption={`Wenn man auf "in Vorschau ansehen" klickt, wird GTM' s Debugger Mode aktiviert. Sobald man die Seite mit dem GTM Container besucht, wird sich der Debugger am unteren Seitenrand automatisch öffnen.`}
        />{" "}
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="Screenshot von Google Tag Manager um den Debugger Mode zu aktivieren"
          caption="Der GTM Debugger Mode ermöglicht es, die Werte aller Variablen in GTM zu inspizieren. Außerdem kann man die Reihenfolge von Ereignissen (links) überblicken und somit prüfen welches Ereignis den Data layer verändert hat."
        />
        <p>
          Falls man keinen Zugang zum Tag Management Container auf einer Webseite hat, aber trotzdem den Data Layer
          debuggen möchte, kann man auch eine <b>Data Layer Chrome Extension</b> nutzen.
        </p>
        <H as="h4">Data Layer Chrome Extensions</H>
        <p>
          Es gibt eine Vielzahl an potentiellen Data layer Extensions zum Debuggen von Analytics und Tag Management
          Systemen. Ich bevorzuge die Extensions, die die meisten Systeme und Anbieter unterstützen, damit ich nicht
          dauernd wechseln muss.
        </p>
        <p>Die folgenden Chrome Extensions sind zur Zeit meine Favoriten beim Data Layer Debugging:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Extension basierend auf{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            und open source, aber mit guter Performance und unterstützt GTM, DTM, Tealium’s Data Layer{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              und viele mehr
            </a>
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - Allrounder mit Unterstützung für Adobe DTM, Launch und Analytics, sowie Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              und mehr
            </a>
          </li>
        </ul>
        <H as="h4">Extensions für Google Analytics und GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - Nach der Aktivierung ist ein neuer Tab in Chrome DevTools (F12, oder strg+shift+i) zugänglich. Alle
            relevanten Daten werden in einem grafischen UI angezeigt und können über mehrere Seitenaufrufe hinweg
            angezeigt werden.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - loggt alle relevanten Daten in die Browserkonsole. Man sollte in den Einstellungen der Browserkonsole
            “Preserve log” aktivieren, damit die Daten über mehrere Seitenaufrufe bestehen bleiben.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - Definitiv die Data Layer Extension mit dem coolsten Namen und Logo und mein ehemaliger Favorit.{" "}
            <a href="https://github.com/sean-adams/dataslayer">Open source</a>, schlichtes Layout und funktioniert
            auch mit Adobe DTM.{" "}
            <u>
              Deaktiviere “use three-column layout where available”, “show GA Classic tags” und “show Floodlight tags”
              in den Einstellungen
            </u>
            , ansonsten wirds zu unübersichtlich.
          </li>
        </ul>
        <H as="h4">Chrome Erweiterungen zum Debuggen von Adobe Analytics, Launch, DTM und Tealium</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - Ermöglicht das Laden der Staging- oder Live-Bibliothek des Tag Management Systems und aktiviert den
            Debug-Mode.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger for Adobe Analytics</b>
            </a>{" "}
            - schaltet den Debugging Mode an. Alternativ kann auch <code>_satellite.setDebug(true)</code> in der
            Browserkonsole eingegeben werden.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - Meta extension zum Debuggen aller Adobe Lösungen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - Nachfolger vom Experience Cloud Debugger, der mehr Übersicht bietet (derzeitig noch in Beta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - Simpel und übersichtlich.
          </li>
        </ul>
        <H as="h2">E-Commerce Data Layer</H>
        <p>
          Data Layer für E-Commerce Webseiten sind umfangreicher und in ihrer Struktur komplexer. Sie müssen eine
          erheblich größere Anzahl an Informationen und Ereignissen verwalten und sind daher aufwendiger in der
          Planung und Implementierung.
        </p>
        <p>
          Bei Google Analytics funktioniert der E-Commerce Report erst, wenn ein entsprechender{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=de">
            E-Commerce Data Layer
          </a>{" "}
          implementiert ist.
        </p>
        <p>
          Große Webshops erfordern jedoch fortgeschrittenere Tracking Setups, weshalb bei Google Analytics spezielle{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            Data Layer für enhanced E-Commerce
          </a>{" "}
          implementiert werden.
        </p>
        <p>
          Bei E-Commerce Data Layern für Google Analytics müssen die Vorgaben für Datenstruktur und Variablennamen
          befolgt werden, damit die E-Commerce Reports in GA funktionieren.
        </p>
        <p>
          Bei anderen Analytics Platformen muss man keinen Vorgaben folgen und kann den Data Layer selbst designen.
        </p>
        <H as="h2">Wann ist ein Data Layer nicht notwendig?</H>
        <p>
          Ein Data Layer braucht nicht unbedingt implementiert werden, wenn der Mehraufwand die vermeintlichen
          Vorteile nicht rechtfertigt.
        </p>
        <p>
          Im obigen Beispiel haben wir verschiedene Daten aus mehreren Quellen (Frontend, Backend, API) und lösen die
          damit verbundenen Tracking Probleme mit einem Data Layer.
        </p>
        <p>
          Viele Webseiten (sog. "Brochure-Webseiten") haben jedoch gar keine Login-Funktion und somit keine Datenbank
          mit Nutzerdaten.
        </p>
        <p>
          Die Häufigkeit von Änderungen auf der Webseite spielt außerdem eine entscheidende Rolle, da Änderungen die
          Selektoren für gewisse Elemente brechen könnten.
        </p>
        <p>
          Vor allem private Blogs oder Webseiten von kleinen Betrieben durchlaufen jedoch selten wesentliche
          Veränderungen im Quellcode der Seite. Selbst Enterprises haben manchmal nur Brochure-Websites mit 50
          Unterseiten auf denen ein Kontaktformular die härteste Konvertierung darstellt.
        </p>
        <p>
          Insofern die benötigten Daten auf erforderlichen Unterseiten verfügbar sind und von wenigen Änderungen
          auszugehen ist, kann daher ein Web Analytics Setup ohne Data Layer aufgezogen werden. Ein Data Layer würde
          das Datensammeln nicht wesentlich sicherer, robuster oder einfacher machen. Der Gewinn entschuldigt daher
          nicht den Mehraufwand.
        </p>
        <p>
          Typische Beispiele wann ein Data Layer unnötig ist, sind also <b>Broschür-</b> oder <b>Content-Webseiten</b>{" "}
          mit wenigen bzw. gar keinen harten Konvertierungn. Üblicherweise beschränkt sich die Analyse auf das
          Herunterbrechen von Ereignissen über Inhaltskategorien.
        </p>{" "}
        <p>
          Solche Anforderungen an ein Analytics Setup können daher schon mit fortgeschrittenem JavaScript und einem
          durchdachten System zur Inhaltsstrukturierung erfüllt werden.
        </p>
        <p>
          Sobald das direkte Einsammeln von Daten via CSS-Selektoren zu aufwändig wird und auf jeden Fall, wenn Daten
          aus der Datenbank benötigt werden, ist ein Data Layer jedoch die Empfehlung.
        </p>
        <p>
          Alternative Lösungen sind ggf. kurzfristig zufriedenstellend, brechen jedoch in der Regel irgendwann und
          sind schwierig in Stand zu halten.
        </p>
        <p>
          Ein Data Layer hat auf lange Sicht gute Chancen zu bestehen, da er ein zentrales Konzept in der Web
          Analytics Branche darstellt und Webbüros zunehmend Erfahrung mit der Implementierung haben. Bei einem
          Bürowechsel kann also davon ausgegangen werden, dass der Data Layer übernommen und in Stand gehalten wird.
        </p>
        <H as="h2">Fazit</H>
        <p>
          Ein Data Layer ist der Standard für professionelle Analytics Setups. Er erhöht die Datenqualität und
          verbessert damit die Datenanalyse generell, ohne die Sicherheit von Kundendaten zu gefährden.
        </p>
        <p>
          Für ambitionierte Webseitenbetreiber die umfangreiche Datenanalyse betreiben wollen, ist ein Data Layer die
          einfachste, robusteste Lösung.
        </p>
        <p>
          Wer die Wahl hat, sollte eine Array-basierten Data Layer Struktur implementieren. Sie ist flexibler, hat
          weniger Abhängigkeiten und eignet sich für alle Typen von Webseiten.
        </p>
        <p>
          Content-Webseiten sind hingegen in ihrer Funktionalität vergleichsweise simpel und brauchen nicht unbedingt
          einen Data Layer, insofern von wenigen Änderungen ausgegangen werden kann. Dies gilt vor allem wenn die
          benötigten Daten auf den Seiten verfügbar sind bzw. über Umwege verfügbar gemacht werden können.
        </p>
        <p>
          Wer selber einen Data Layer implementieren will, hat es mit Wordpress am einfachsten. Umfangreiche Projekte
          sollten jedoch zusammen mit einem Analytics Consultant geplant und gesteuert werden, um Zeit und
          Implementierungskosten zu sparen.
        </p>
        <p>
          Ich empfehle Interessierten sich auf jeden Fall eine Data Layer Chrome Extension zu installieren und damit
          einige populäre Webseiten zu inspizieren - aus generellem Interesse und um Inspiration zu neuen
          Erfolgsindikatoren (KPIs) zu finden 😉.
        </p>
        <H as="h2">Data Layer Dokumentation der verschiedenen TMS-Anbieter</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Erstellung und Änderungen des Data Layers
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Erstellung des Data Layers
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Erstellung
            </a>{" "}
            und{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              Änderungen des Data Layers
            </a>
          </li>

          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Erstellung
            </a>{" "}
            und{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              Änderungen des Data Layers
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Erstellung
            </a>{" "}
            und{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              Änderungen des Data Layers
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Was ist ein Beispiel für einen Data Layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ein Beispiel für einen Data Layer wird im Artikel gegeben. Ein JavaScript-Objekt speichert Daten von einer Website, Datenbank oder einer externen Quelle auf zentrale, flexible und leicht zugängliche Weise. Ein Beispielcode-Snippet für die Initiierung eines Data Layers für Google Tag Manager ist: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"Kategorieseite\", \"pageName\": \"Sneaker-Übersicht\", \"language\": \"en-US\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "Was sind Data Layer Variablen?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data Layer Variablen sind Schlüssel-Wert-Paare innerhalb des Data Layers, die spezifische Informationen speichern. Diese Variablen können Seitenmerkmale, Benutzerverhaltensdaten und mehr umfassen und dienen als zentrales Datenrepository für Analytik und Tracking."
              }
            },
            {
              "@type": "Question",
              "name": "Warum sollte man einen Data Layer verwenden?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ein Data Layer ist wesentlich für robuste, flexible und sichere Datensammlung. Er zentralisiert Daten aus verschiedenen Quellen, macht sie leicht zugänglich und konsistent über verschiedene Webseiten und Benutzerinteraktionen hinweg. Dieser Ansatz verbessert die Datenqualität und -zuverlässigkeit, was für datengesteuerte Entscheidungsfindung entscheidend ist."
              }
            },
            {
              "@type": "Question",
              "name": "Brauche ich einen Data Layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Obwohl nicht immer notwendig, wird ein Data Layer generell für diejenigen empfohlen, die ernsthaft ihre Datenanalyse-Ambitionen verfolgen. Er bietet Datenqualität, Zuverlässigkeit und langfristige Zeitersparnis, die den höheren Implementierungsaufwand rechtfertigen."
              }
            },
            {
              "@type": "Question",
              "name": "Was sind die Vorteile eines Data Layers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Die Vorteile eines Data Layers umfassen: Verfügbarkeit von Daten unabhängig von ihrer Sichtbarkeit auf der Seite. Robuste Datensammlung. Verminderung von Datenverlust bei asynchronen Datenanfragen. Sichere Datensammlung aus mehreren Quellen."
              }
            },
            {
              "@type": "Question",
              "name": "Haben alle Websites einen Data Layer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Nicht alle Websites haben einen Data Layer. Seine Implementierung hängt von der Komplexität der Website und der Tiefe der erforderlichen Datenanalyse ab. Einfache Websites haben möglicherweise keinen Data Layer, während komplexere Seiten, insbesondere solche, die auf datengesteuerte Entscheidungsfindung fokussiert sind, wahrscheinlich einen haben werden."
              }
            },
            {
              "@type": "Question",
              "name": "Wie greife ich auf den Data Layer zu?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Der Data Layer ist global in der Browser-Console zugänglich. Für Websites mit Google Tag Manager kannst du auf ihn dataLayer oder Object.assign(...dataLayer) zugreifen. Für Adobe Launch oder DTM kannst du ihn mit digitalData zugreifen."
              }
            },
            {
              "@type": "Question",
              "name": "Wie fügt man Daten zum Data Layer hinzu?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Um Daten zum Data Layer hinzuzufügen, verwendest du die Methode dataLayer.push(). Zum Beispiel: window.dataLayer.push({ \"event\": \"Kursbuchung\", \"startWeek\": \"24\" }); Diese Methode wird verwendet, um neue Daten oder Änderungen zum Data Layer hinzuzufügen. Der Event-Schlüssel kann verwendet werden, um eine weitere Tag-Ausführung im Tag-Management-System auszulösen."
              }
            }
          ]
        }} />
        <H as="h2">Weitere Ressourcen</H>
        <ul>
          <li>
            Simo Ahava über den{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              Data Layer in GTM
            </a>{" "}
            mit{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              praktischen Beispielen
            </a>
          </li>
          <li>Lies mein Tutorial über das <Link to="/de/google-tag-manager-einrichten">Google Tag Manager Einrichten</Link></li>
          <li>
            Kevin Haag’s Präsentation vom Measurecamp Berlin 2019 über den{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer in Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default DataLayer;
